import React from "react";
import styled from "styled-components";
import { SocialLinks } from "../components/SocialLinks";

export const ui = {
  Container: styled.div`
    padding: 80px 25px 0;
    max-width: 660px;
    margin: 0 auto;
  `,
  TitleContainer: styled.div`
    font-weight: 800;

    margin-bottom: 1em;
    &,
    & > * {
      font-size: 30px;
    }
  `,
  Name: styled.h1`
    display: inline;
  `,
  Description: styled.div`
    line-height: 1.6em;
    margin-bottom: 2em;
    word-wrap: break-word;
  `,
};

export default function Home() {
  return (
    <ui.Container>
      <ui.TitleContainer>
        Hi, I'm <ui.Name>Elad Ossadon</ui.Name>
      </ui.TitleContainer>

      <ui.Description>
        <p>A Full-Stack Software Engineer and Architect in New York City.</p>
        <p>
          Currently focusing on Front End infrastructure, UI, build tools and
          developer experience. Supporting hundreds of engineers in being more
          efficient and productive in their work.
        </p>
        <p>
          Webpack, Rollup, Yarn Workspaces, Monorepo and shared packages,
          Next.js, local development, testing and linting tools, React /
          TypeScript / Node.js.
        </p>
      </ui.Description>

      <SocialLinks />
    </ui.Container>
  );
}
