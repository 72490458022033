import React, { useState } from "react"
import styled, { keyframes } from "styled-components"

const glow = keyframes`
  from {
    text-shadow: 0px 0px 0 #ff00bf;
  }

  to {
    text-shadow: 0px 0px 8px #ff00bf;
  }
`

export const ui = {
  Container: styled.div``,
  List: styled.div``,
  LinkContainer: styled.div`
    display: inline-block;
    position: relative;
  `,
  Link: styled.a`
    font-size: 30px;
    padding-right: 15px;
    &,
    &:-webkit-any-link {
      text-decoration: none;
    }

    &:hover {
      animation-duration: 0.5s;
      animation-name: ${glow};
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  `,
  LinkTitle: styled.div`
    position: absolute;
    top: 100%;
    margin-top: 5px;
  `,
}

export const socialLinks = [
  {
    href: "https://twitter.com/elado",
    title: "Twitter",
    icon: "🦜",
  },
  {
    href: "https://github.com/elado",
    title: "GitHub",
    icon: "🐙",
  },
  {
    href: "https://linkedin.com/in/elado",
    title: "LinkedIn",
    icon: "💼",
  },
  {
    href: "https://medium.com/@elado",
    title: "Medium",
    icon: "📗",
  },
  {
    href: "https://instagram.com/elado",
    title: "Instagram",
    icon: "🌉",
  },
  {
    href: "https://fb.me/elado",
    title: "Facebook",
    icon: "😎",
  },
  {
    href: "mailto:elad@ossadon.com",
    title: "Email",
    icon: "💌",
  },
]

export const SocialLinks = () => {
  const [hover, setHover] = useState<string | null>(null)

  return (
    <ui.Container>
      <ui.List>
        {socialLinks.map(link => (
          <ui.LinkContainer>
            <ui.Link
              href={link.href}
              target="_blank"
              onMouseEnter={() => setHover(link.title)}
              onMouseLeave={() => setHover(null)}
            >
              {link.icon}
            </ui.Link>

            {link.title === hover && <ui.LinkTitle>{hover}</ui.LinkTitle>}
          </ui.LinkContainer>
        ))}
      </ui.List>
    </ui.Container>
  )
}
